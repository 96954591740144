<template>
  <div>
    <el-card style="min-height: 45rem">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form label-position="top">
            <el-form-item label="商品采集接口API KEY">
              <el-input v-model="form.import_goods_api_key"></el-input>
            </el-form-item>
            <el-form-item label="物流查询接口用户ID">
              <el-input v-model="form.express_appid"></el-input>
            </el-form-item>
            <el-form-item label="物流查询接口API key">
              <el-input v-model="form.express_app_key"></el-input>
            </el-form-item>
            <el-form-item label="强制邀请码">
              <el-switch v-model="form.must_invite_code"></el-switch>
            </el-form-item>
            <el-form-item label="注册协议">
              <y_editor eid="login_agree" v-model="form.login_agree"></y_editor>
            </el-form-item>
            <el-form-item label="用户协议">
              <y_editor eid="member_agree" v-model="form.member_agree"></y_editor>
            </el-form-item>
            <el-form-item label="隐私条款">
              <y_editor eid="privacy_agree" v-model="form.privacy_agree"></y_editor>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="12">
          <el-form>
            <el-form-item label="微信提现">
              <el-switch v-model="form.withdrawal_wechat"></el-switch>
            </el-form-item>
            <el-form-item label="公众号绑定、关注引导">
              <el-switch v-model="form.home_wechat_official_tips"></el-switch>
            </el-form-item>
            <el-form-item label="客服二维码">
              <y_upload_img v-model="form.waiter_qrcode"></y_upload_img>
            </el-form-item>
            <el-form-item label="首页分享图片">
              <y_upload_img v-model="form.index_share_pic"></y_upload_img>
            </el-form-item>
            <el-form-item label="首页分享标题">
              <el-input v-model="form.index_share_title"></el-input>
            </el-form-item>
            <el-form-item label="首页分享描述">
              <el-input v-model="form.index_share_desc"></el-input>
            </el-form-item>
            <el-form-item label="关于我们顶部背景">
              <y_upload_img v-model="form.top_bg_about"></y_upload_img>
            </el-form-item>
            <el-form-item label="佣金记录顶部背景">
              <y_upload_img v-model="form.top_bg_award"></y_upload_img>
            </el-form-item>
            <el-form-item label="个人中心顶部背景">
              <y_upload_img v-model="form.top_bg_center"></y_upload_img>
            </el-form-item>
            <el-form-item label="我的用户顶部背景">
              <y_upload_img v-model="form.top_bg_client"></y_upload_img>
            </el-form-item>
            <el-form-item label="订单详情顶部背景">
              <y_upload_img v-model="form.top_bg_order"></y_upload_img>
            </el-form-item>
            <el-form-item label="导航栏背景色">
              <y_choose_color v-model="form.mp_top_nav_bg_color"></y_choose_color>
              <div class="y-desc" style="line-height: 1rem">仅非h5端有效</div>
            </el-form-item>
            <el-form-item label="导航栏文本色">
              <el-radio-group v-model="form.mp_top_nav_text_color">
                <el-radio label="#ffffff">白色</el-radio>
                <el-radio label="#000000">黑色</el-radio>
              </el-radio-group>
              <div class="y-desc" style="line-height: 1rem">仅非h5端有效,各平台表现差异正常</div>
            </el-form-item>
            <el-form-item>
              <el-button @click="edit" type="primary">保存</el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import y_upload_img from "@/components/y_upload/y_upload_img";
import y_editor from "@/components/editor/y_editor";
import y_choose_color from "@/components/y_choose_color";
export default {
  name: "info",
  components:{
    y_upload_img,
    y_editor,
    y_choose_color
  },
  data() {
    return {
      form:{
        import_goods_api_key:"",

        express_appid:"",
        express_app_key:"",

        must_invite_code:false,
        waiter_qrcode:"",
        login_agree:"",
        member_agree:"",
        privacy_agree:"",

        index_share_title:"",
        index_share_desc:"",
        index_share_pic:"",

        top_bg_center:"",
        top_bg_about:"",
        top_bg_award:"",
        top_bg_client:"",
        top_bg_order:"",
        mp_top_nav_bg_color:"",
        mp_top_nav_text_color:"",
        withdrawal_wechat:false,
        home_wechat_official_tips:false,
      }
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.$api.system.info.sysInfo().then(res=>{
        this.form = res;
      })
    },
    edit(){
      this.$api.system.info.sysInfoEdit(this.form).then(()=>{
        this.$message.success("操作成功");
        this.load();
      })
    },
  }
}
</script>

<style scoped>

</style>